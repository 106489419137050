import { FormControlLabelProps } from '@mui/material';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CasRHFSwitchBase from './CasRHFSwitchBase';

interface IProps extends Omit<FormControlLabelProps, 'control'> {
  onCustomChange?: (value: boolean) => void;
  name: string;
  helperText?: React.ReactNode;
}

export default function CasRHFSwitch({ onCustomChange, labelPlacement = 'end', ...props }: IProps) {
  const { control } = useFormContext();

  const currentValue = useWatch({
    control,
    name: props.name, // the name of the input you want to watch
  });

  useMemo(() => {
    if (onCustomChange) onCustomChange(currentValue);
  }, [currentValue, onCustomChange]);

  return <CasRHFSwitchBase {...props} labelPlacement={labelPlacement} />;
}
