import { lazy, useContext } from 'react';

import SvgColor from 'src/components/svg-color';
import { AuthContext } from 'src/context/AuthContextProvider';

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

export const ICONS = {
  user: icon('ic_user'),
  dashboard: icon('ic_dashboard'),
};

// Commissions
const SettingPage = lazy(() => import('src/pages/commissions/SettingsPage'));
const LrpCommissionsPage = lazy(() => import('src/pages/commissions/LrpCommissionsPage'));
const CmeCommissionsPage = lazy(() => import('src/pages/commissions/CmeCommissionsPage'));
const MonthlyProcessingPage = lazy(() => import('src/pages/commissions/MonthlyProcessingPage'));
const CommissionsDataPage = lazy(() => import('src/pages/commissions/CommissionsDataPage'));
const MyCommissionsPage = lazy(() => import('src/pages/commissions/MyCommissionsPage'));

// risk Management
const NavigatorPage = lazy(() => import('src/pages/riskManagement/NavigatorPage'));
const SceReportsPage = lazy(() => import('src/pages/riskManagement/SceReportsPage'));
const CommunicationsPage = lazy(() => import('src/pages/riskManagement/CommunicationsPage'));

// Utilities
const AppendToBasisPage = lazy(() => import('src/pages/utilities/AppendToBasisPage'));
const DailyPasswordPage = lazy(() => import('src/pages/utilities/DailyPasswordPage'));
const PipPage = lazy(() => import('src/pages/utilities/PipPage'));
const PoManagementPage = lazy(() => import('src/pages/utilities/PoManagementPage'));

// admin page
const SettingsPage = lazy(() => import('src/pages/administration/SettingsPage'));
const EHarvestPage = lazy(() => import('src/pages/administration/EHarvestPage'));

// SCE
const ScePage = lazy(() => import('src/pages/sce/SceEntryPage'));
const SceOrdersPage = lazy(() => import('src/pages/sce/SceOrdersPage'));
const LrpQuotesPage = lazy(() => import('src/pages/sce/LrpQuotesPage'));

export type SubMenu = {
  title: string;
  path: string;
  element: React.ReactElement;
  permission: string;
};

export type Menu = {
  menuTitle: string;
  path: string;
  icon?: React.ReactElement;
  subMenus: SubMenu[];
};

const menus: Menu[] = [
  // RiskManagement
  {
    menuTitle: 'Risk Management',
    path: 'riskmanagement',
    icon: ICONS.user,
    subMenus: [
      {
        title: 'Communications',
        path: 'communications',
        element: <CommunicationsPage />,
        permission: 'COMMUNICATIONS',
      },
      {
        title: 'Navigator',
        path: 'navigator',
        element: <NavigatorPage />,
        permission: 'NAVIGATOR',
      },
      {
        title: 'SCE Reports',
        path: 'scereports',
        element: <SceReportsPage />,
        permission: 'SCE_REPORTS',
      },
    ],
  },
  // Commissions
  {
    menuTitle: 'Commissions',
    path: 'commissions',
    icon: ICONS.user,
    subMenus: [
      {
        title: 'My Commissions',
        path: 'mycommissions',
        element: <MyCommissionsPage />,
        permission: 'MY_COMMISSIONS',
      },
      {
        title: 'Data',
        path: 'data',
        element: <CommissionsDataPage />,
        permission: 'COMMISSIONDATA',
      },
      {
        title: 'Monthly Processing',
        path: 'monthlyprocessing',
        element: <MonthlyProcessingPage />,
        permission: 'MONTHLYCOMMISSION',
      },
      {
        title: 'CME Commissions',
        path: 'cmecommissions',
        element: <CmeCommissionsPage />,
        permission: 'CMECOMMISSION',
      },
      {
        title: 'LRP Commissions',
        path: 'lrpcommissions',
        element: <LrpCommissionsPage />,
        permission: 'LRPCOMMISSION',
      },
      {
        title: 'Settings',
        path: 'settings',
        element: <SettingPage />,
        permission: 'SETTING',
      },
    ],
  },

  // utilities
  {
    menuTitle: 'Utilities',
    path: 'utilities',
    icon: ICONS.user,
    subMenus: [
      {
        title: 'Append to Basis',
        path: 'appendtobasis',
        element: <AppendToBasisPage />,
        permission: 'UTILITIES_BASIS',
      },
      {
        title: 'Daily Password',
        path: 'dailypassword',
        element: <DailyPasswordPage />,
        permission: 'DAILY_PWD',
      },
      {
        title: 'PIP',
        path: 'pip',
        element: <PipPage />,
        permission: 'UTILITIES_PIP',
      },
      {
        title: 'PO Management',
        path: 'pomanagement',
        element: <PoManagementPage />,
        permission: 'UTILITIES_PO_MGT',
      },
    ],
  },

  // Endoresement
  {
    menuTitle: 'Endorsements',
    path: 'endorsements',
    icon: ICONS.user,
    subMenus: [
      {
        title: 'SCE Entry',
        path: 'sce',
        element: <ScePage />,
        permission: 'SCEENTRY',
      },
      {
        title: 'SCE Orders',
        path: 'sceorders',
        element: <SceOrdersPage />,
        permission: 'SCEORDERS',
      },
      {
        title: 'LRP Quotes',
        path: 'lrpquotes',
        element: <LrpQuotesPage />,
        permission: 'LRP_QUOTES',
      },
    ],
  },
  // Administration
  {
    menuTitle: 'Administration',
    path: 'administration',
    icon: ICONS.user,
    subMenus: [
      {
        title: 'Settings',
        path: 'settings',
        element: <SettingsPage />,
        permission: 'ADMIN',
      },
      {
        title: 'eHarvest',
        path: 'eharvest',
        element: <EHarvestPage />,
        permission: 'ADMIN_EHARVEST',
      },
    ],
  },
];

function hasCrudPermission(userPermissions: string[], permission: string) {
  return ['C_', 'R_', 'U_', 'D_'].some((prefix) =>
    userPermissions.includes(`${prefix}${permission}`)
  );
}

export function useFilteredNavigation() {
  const { userPermissions } = useContext(AuthContext);

  const filteredMenu = menus
    .map((section) => ({
      ...section,
      subMenus: section.subMenus
        .filter((subMenu) => hasCrudPermission(userPermissions || [], subMenu.permission))
        .map((subMenu) => ({
          ...subMenu,
          path: `/${section.path}/${subMenu.path}`, // Concatenate base+ parent path with child path
        })),
    }))
    .filter((section) => section.subMenus.length > 0); // Filter out sections with no subMenus

  return filteredMenu;
}
