import { useMemo, useState, useEffect } from 'react';
import { type MRT_TableInstance, type MRT_RowData } from 'material-react-table';
import valueClassname from 'src/shared/util/valueClassname';

import CasSelect from '../CasSelect/CasSelect';

type CasMRTableFilterProps = {
  columnId: string;
  table: MRT_TableInstance<MRT_RowData>;
  resetFilters: boolean;
};

// Function to get unique values for a column with All Option
const getUniqueColumnValuesWithAllOption = (
  accessor: string,
  data: MRT_RowData[]
): { label: any; value: any }[] => {
  const uniqueValues = new Set(data.map((row) => row[accessor]));
  const options = Array.from(uniqueValues).map((value) => ({
    label: value,
    value,
  }));
  options.unshift({ label: 'All', value: '' });
  return options;
};

export default function CasMRTableFilter({ columnId, table, resetFilters }: CasMRTableFilterProps) {
  const [selectValue, setSelectValue] = useState('');
  const rowsData = table.getRowModel().rows.map((data) => data.original);
  const columnDef = table.getAllColumns().find((col) => col.id === columnId)?.columnDef;
  const { header } = columnDef || {};

  const uniqueValues = useMemo(() => {
    if (!header) return [];
    return getUniqueColumnValuesWithAllOption(columnId, rowsData);
  }, [columnId, rowsData, header]);

  // reset selectValue to empty when api response changes
  useEffect(() => {
    if (resetFilters) setSelectValue('');
  }, [resetFilters, table]);

  if (!header) return null;

  const handleFilters = (event: any) => {
    const newValue = event.target.value;
    setSelectValue(newValue);
    const currentFilters = table.getState().columnFilters;
    const existingFilterIndex = currentFilters.findIndex((filter) => filter.id === columnId);
    let newFilters;

    if (existingFilterIndex > -1) {
      newFilters = currentFilters.map((filter, index) =>
        index === existingFilterIndex ? { ...filter, value: newValue } : filter
      );
    } else {
      newFilters = [...currentFilters, { id: columnId, value: newValue }];
    }

    table.setColumnFilters(newFilters);
  };

  return (
    <CasSelect
      sx={{ minWidth: Math.max(85, header.length * 9) }}
      className={valueClassname(selectValue)}
      key={header}
      name={header}
      label={header}
      placeholder={header}
      value={selectValue}
      onChange={handleFilters}
      options={uniqueValues}
    />
  );
}
