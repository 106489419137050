import { MenuItem } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { CasRHFSelectBase } from './CasRHFSelectBase';

export default function CasRHFSelect({
  options,
  optionsIdField = 'id',
  optionsNameField = 'name',
  onCustomChange,
  defaultValue,
  name,
  ...rest
}: any) {
  const { setValue, getValues, control } = useFormContext();

  const currentValue = useWatch({control, name});

  // Set the default value when the component mounts
  useEffect(() => {
    const selectValue = getValues(name);
    if (defaultValue && !selectValue) setValue(name, defaultValue);
  }, [setValue, name, defaultValue, getValues]);

  // **** Was useMemo - causing SCE entry to hang - changing to useEffect fixed
  useEffect(() => {
    if (onCustomChange) onCustomChange(currentValue);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  return (
    <CasRHFSelectBase
      currentValue={currentValue}
      {...rest}
      name={name}
      defaultValue={defaultValue}
      InputLabelProps={{
        shrink: !!currentValue, // This will shrink the label when the value is not empty
      }}
    >
      {options.map((option: any) => (
        <MenuItem key={option[optionsIdField]} value={option[optionsIdField]}>
          {option[optionsNameField]}
        </MenuItem>
      ))}
    </CasRHFSelectBase>
  );
}
