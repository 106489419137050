import { useRetrieveEntityList } from 'src/shared/hooks/reactQuery/useRetrieveEntityList';
import { Entity } from 'src/types/common.types';
import CasRHFSelect from '../CasSelect/CasRHFSelect';

export function useRiskManager() {
  const { data, isLoading } = useRetrieveEntityList({entity: Entity.EMPLOYEE_RISK_MANAGER});
  return [data?.result || [], isLoading];
}

export default function CasRiskManagerSelect(props: any) {
  const [options, isLoading] = useRiskManager();

  return <CasRHFSelect {...props} loading={isLoading} options={options} />;
}
